import React from 'react'
import { Container, Certificate } from './styled'

const Certificates: React.FC = () => (
  <Container>
    <Certificate
      src="/images/certificates/certificateISO.png"
      alt="certificate ISO"
    />
    <Certificate
      src="/images/certificates/certificateSOC.png"
      alt="certificate SOC"
    />
    <Certificate
      src="/images/certificates/certificateSOC2.png"
      alt="certificate SOC2"
    />
  </Container>
)

Certificates.defaultProps = {}

Certificates.propTypes = {}

export default Certificates
