import styled, { css, keyframes } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const wait = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
  ${breakpoints({
    xl: css`
      height: 100%;
      padding: 0 0 0 0;
    `,
  })}
  animation: ${wait} 3s linear, ${fadeIn} 3s cubic-bezier(0.33, 1, 0.68, 1) 3s;
`

export const Certificate = styled.img`
  height: 80px;
  width: 80px;
  ${breakpoints({
    xl: css`
      height: 150px;
      width: 150px;
    `,
  })}
`
